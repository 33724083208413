import { apiClient } from "@netgame/openapi";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import useAppInitData from "./useAppInitData";
import useAsyncFetch from "./useAsyncFetch";
import useIsGuest from "./useIsGuest";
import useOtpGetFlow from "./useOtpGetFlow";

dayjs.extend(utc);
dayjs.extend(timezone);

type Social = {
	id: string;
	name: string;
	href: string;
	icon: string;
	type: "facebookAuthEnabled" | "googleAuthEnabled";
};

type UserData = {
	login: string;
	password: string;
};

type RegisterData = {
	contact: string;
	password: string;
	agree: boolean;
	timezone: string;
	refcode?: string;
	traffic_source?: string;
};

type ErrorMsgs = {
	notValid: string;
	notCompleted: string;
};

type ModalOpen<K extends string, P> = (name: K, props?: P) => void;
type ModalClose<K extends string> = (name: K) => void;

const socials: Social[] = [
	{
		id: "gp",
		name: "Google",
		href: "/login/Google/",
		icon: "google",
		type: "googleAuthEnabled"
	},
	{
		id: "fb",
		name: "Facebook",
		href: "/login/facebook/",
		icon: "fb",
		type: "facebookAuthEnabled"
	}
];

const useSignup = <K extends string, P>(
	errorMsgs: ErrorMsgs,
	open: ModalOpen<K, P>,
	close: ModalClose<K>,
	isClearNuxtData = true
) => {
	const minPasswordLength = 5;
	const error = ref("");
	const acceptRestrictedStates = ref(false);
	const isAcceptRestrictedStatesError = ref(false);
	const acceptTerms = ref(false);
	const isAcceptTermsError = ref(false);
	const errorMessage = ref("");
	const errorSignup = ref("");
	const route = useRoute();
	const isGuest = useIsGuest();
	const signupLoading = ref(false);
	const { data, pending: promoLoading } = useAsyncFetch({
		path: "/rest/player/promo/register/",
		method: "get",
		options: {
			cached: true
		}
	});
	const { data: appInit, refresh } = useAppInitData();
	const { data: otpGetData } = useOtpGetFlow(true);

	const promoRegistration = computed(() => data.value?.data);
	const isShowPassword = computed(() => !appInit.value?.enabledOneClickRegistration);

	const email = ref("");
	const isEmailTouched = ref(false);
	const isEmailValid = computed(() => validateEmail(email.value));
	const isEmailShowError = computed(
		() => (!isEmailValid.value && isEmailTouched.value) || (!!error.value && error.value !== "agree")
	);

	const password = ref("");
	const isPasswordTouched = ref(false);
	const isPasswordValid = computed(() => validateStringLength(password.value, minPasswordLength));
	const isPasswordShowError = computed(() => !isPasswordValid.value && isPasswordTouched.value);

	const isButtonEnabled = computed(() => {
		if (isShowPassword.value) {
			return !!isEmailValid.value && !!isPasswordValid.value;
		}
		return !!isEmailValid.value;
	});
	const isFullButtonEnabled = computed(
		() => isButtonEnabled.value && acceptRestrictedStates.value && acceptTerms.value
	);
	const isHideSocials = route.query.openModal !== "register" && route.query.openModal !== undefined;
	const resetError = () => {
		errorMessage.value = "";
		error.value = "";
		errorSignup.value = "";
	};

	const isAgree = computed(() => !!acceptTerms.value && !!acceptRestrictedStates.value);
	const socialsOtp = computed(() => socials.filter(({ type }) => otpGetData.value?.registration_form?.[type]));

	const changedFields: Record<string, boolean> = {};

	const handleInput = (field: string) => {
		if (!changedFields[field]) {
			dispatchGAEvent({
				event: "change_form",
				formName: "registration",
				formField: field
			});
			changedFields[field] = true;
		}
		resetError();
	};

	const sendRegisterData = (body: RegisterData) =>
		apiClient({
			path: "/rest/registerV2/",
			method: "post",
			parameters: { body }
		});

	const sendLoginData = (body: UserData) =>
		apiClient({
			path: "/rest/login/",
			method: "post",
			parameters: { body }
		});

	const login = async (registerData: RegisterData) => {
		const { contact: login, password } = registerData;
		const data: UserData = {
			login,
			password
		};
		const response = await sendLoginData(data);
		if (response && response.success) {
			isGuest.value = false;
			window.scrollTo({ top: 0, behavior: "instant" as ScrollBehavior });
			clearNuxtData("/rest/app/init/");
			await refresh();
			const cookie = useCookie("showWelcomePopup", {
				expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
				path: "/"
			});
			cookie.value = "true";
			close("LazyOModalSignup" as K);
		}
	};

	const register = async () => {
		signupLoading.value = true;
		const code = route.query.invited_by;
		const data: RegisterData = {
			contact: email.value,
			password: password.value,
			agree: isAgree.value,
			timezone: dayjs.tz.guess(),
			...(code && typeof code === "string"
				? { [code.length === 6 ? "refcode" : "campaign_ref_code"]: code.toUpperCase() }
				: {}),
			...(appInit.value?.enabledOneClickRegistration ? { traffic_source: "oneClick" } : {})
		};

		const response = await sendRegisterData(data);
		signupLoading.value = false;

		if (response && response.success) {
			isGuest.value = false;
			window.scrollTo({ top: 0, behavior: "instant" as ScrollBehavior });
			if (isClearNuxtData) {
				clearNuxtData("/rest/app/init/");
			}
			refresh();
			dispatchFBEvents("trackCustom", "RegistrationSuccess", {
				event_name: "RegistrationSuccess",
				event_time: Date.now(),
				action_source: "website"
			});

			dispatchGAEvent({
				event: "registration",
				location: "header",
				type: appInit.value?.activeSocialAccounts?.[0] ?? "email",
				step: "success",
				playerId: appInit.value?.login || ""
			});

			close("LazyOModalSignup" as K);
			setTimeout(() => {
				open("LazyOModalWelcome" as K);
			}, 1000);
		} else {
			const { contactExist, type, message } = response;
			error.value = type || "";
			errorMessage.value = message || "";

			if (contactExist && contactExist.length !== 0) {
				await login(data);
			}
		}
		if (response.message) {
			error.value = response.message;
		}
	};

	const handleSubmit = async () => {
		dispatchGAEvent({
			event: "click_button",
			location: "registration_window",
			button_name: "registration"
		});
		if (!isAgree.value) {
			error.value = "agree";
			errorMessage.value = errorMsgs.notCompleted;

			if (!acceptTerms.value) {
				isAcceptTermsError.value = true;
			}
			if (!acceptRestrictedStates.value) {
				isAcceptRestrictedStatesError.value = true;
			}
			return;
		}
		if (isEmailValid.value && (isPasswordValid.value || appInit.value?.enabledOneClickRegistration)) {
			await register();
		} else {
			errorSignup.value = errorMsgs.notValid;
		}
	};

	const handleClickLogin = () => {
		open("LazyOModalLogin" as K, undefined as P);
		close("LazyOModalSignup" as K);
	};

	watch([acceptTerms, acceptRestrictedStates], () => {
		if (acceptTerms.value) {
			isAcceptTermsError.value = false;
		}
		if (acceptRestrictedStates.value) {
			isAcceptRestrictedStatesError.value = false;
		}
		resetError();
	});

	return {
		email,
		password,
		isShowPassword,
		socials,
		socialsOtp,
		signupLoading,
		acceptTerms,
		acceptRestrictedStates,
		promoRegistration,
		promoLoading,
		error,
		errorSignup,
		errorMessage,
		isHideSocials,
		isAcceptTermsError,
		isAcceptRestrictedStatesError,
		isButtonEnabled,
		isEmailShowError,
		isEmailTouched,
		isPasswordShowError,
		isPasswordTouched,
		isFullButtonEnabled,
		handleInput,
		resetError,
		handleSubmit,
		handleClickLogin
	};
};

export default useSignup;
